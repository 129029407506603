import * as React from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../images/logo.jpg';
import banner from '../images/banner.png';
import Navbar from './navbar/Navbar';
import {COLORS} from '../resources/colors';

const Header = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <AppBar position="static" sx={{backgroundColor: COLORS.headerBg, marginBottom: '20px'}} data-testid="header">
      <Box sx={{position: 'relative', width: '100%', marginBottom: '20px'}}>
        {isLargeScreen && (
          <Box
            component="img"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
            }}
            src={banner} // TODO: Crop the blank space from the image
            alt="Side Decorative"
          />
        )}
        <Toolbar sx={{justifyContent: 'center', minHeight: '200px'}}>
          <IconButton component={Link} to="/" sx={{'&:hover': {backgroundColor: 'transparent'}}}>
            <img src={logo} alt="Company Logo" style={{maxHeight: '200px', maxWidth: '200px'}} />
          </IconButton>
        </Toolbar>
        <Navbar /> {/* Navigation bar below the Toolbar */}
      </Box >
    </AppBar>
  );
};

export default Header;
