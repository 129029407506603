/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {Typography, Divider} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import image1 from './images/gradGreggs.jpeg'; // Updated import statements
import image2 from './images/ajpfp.jpeg';

function FormattedText({text}) {
  return (
    <div dangerouslySetInnerHTML={{__html: text}} />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const AboutPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  const text1 = `
    I am a psychology graduate based in the North East who specialises in autism and ADHD. I was diagnosed with ADHD at the age of 15 and, while this helped explain some of the ways that I was feeling and how I was experiencing things, it never felt like the full story. I went on to study psychology with child development at university which is when I realized, I was autistic. The fact that I was given an autism support mentor may also have been a sign! Living away from my neurodivergent home, in a largely neurotypical world, I learned more about who I am and how I experienced life. I was given a formal diagnosis aged 20.<br/>
    <br/>
    I am passionate about sharing my lived experiences and educating others about what it is to be neurodivergent. I am also passionate about supporting others who are on the same journey.<br/>
    <br/>
    I believe strongly that if you want to learn about neurodivergency, the best people to talk to are neurodivergent people themselves.<br/>
    <br/>
    What do I do?<br/>
    - I work alongside neurodivergent children in schools<br/>
    - I deliver a range of training to professionals and families<br/>
    - I create training materials and resources for other training providers to support the delivery of neuro-affirming courses<br/>
    - I create animations for training, and I work alongside children and create animations to support them to explore their own diagnosis in new, neuro-affirming ways<br/>
    - I am involved with a number of strategy groups looking at support and provision for neurodivergent people across local authorities<br/>
  `;

  const images = [
    image1,
    image2,
  ];

  const renderImagesForMobile = () => {
    if (isMobile) {
      return (
        <img
          src={image1}
          alt="Image 1"
          style={{width: '100%', marginTop: '20px'}}
        />
      );
    }
    return null;
  };

  return (
    <div style={{fontSize}}>
      <Helmet>
        <title>AJ's Brain | About</title>
        <meta
          name="description"
          content="The story of me and AJ's Brain"
        />
      </Helmet>
      <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
        About
      </Typography>
      <Divider />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {isMobile ? (
          // On mobile, text takes the whole width
          <div style={{flex: '0 0 90%', maxWidth: '100%', marginTop: '20px'}}>
            <Typography variant="body" gutterBottom>
              <FormattedText text={text1} />
            </Typography>
            {renderImagesForMobile()}
          </div>
        ) : (
          // On desktop, 70% for text and 30% for images with padding
          <>
            <div style={{flex: '0 0 70%', maxWidth: '70%', marginTop: '50px'}}>
              <Typography variant="body" gutterBottom>
                <FormattedText text={text1} />
              </Typography>
            </div>
            <div style={{flex: '0 0 25%', textAlign: 'center', paddingTop: '50px', paddingLeft: '20px', paddingRight: '20px'}}>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                  style={{width: '100%', marginBottom: '20px'}}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AboutPage;
