import React from 'react';
import {Helmet} from 'react-helmet-async';
import useMediaQuery from '@mui/material/useMediaQuery';
import GridSection from '../sections/gridSection/GridSection';
import Testimonials from '../sections/testimonials/Testimonials';
import ajImage from './images/aj_animated.jpg';
import trainingImage from './images/Training_1.jpg';
import trainingBedlington from './images/Training_Bedlington.jpeg';
import trainingHall from './images/Training_Hall.jpeg';
import ajScreen from './images/AJ_Screen.jpg';
import animationsVid from './images/videos/vid1.mp4';
import resourcesImage from './images/resources.jpg';
import PropTypes from 'prop-types';
import './Home.css';

function FormattedText({text}) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const Home = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  // About info
  const description1 = `
    Hi, I'm AJ!   I'm autistic and have ADHD - welcome to my brain!<br/>
    <br/>
    I am a psychology graduate now working as a neurodiversity consultant and trainer. I also work part-time as a teaching assistant.<br/>
    <br/>
    I have created a variety of animations and training materials for schools, local authority teams, autism services and universities.<br/>
    <br/>
    I also deliver autism and ADHD training to schools, families, businesses and health professionals.<br/>
    <br/>
    I am passionate about using my lived experience of being autistic and ADHD to educate and advocate for a neuro-affirming approach through AJ’s Brain.    
  `;

  // Training info
  const description2 = `
    I provide courses and training to meet a range of requirements for businesses, schools, medical professionals and families. This training combines theoretical knowledge and understanding with lived experience, explaining how the neurodivergent brain works and sharing how this looks and feels in real life.<br/>
    <br/>
    I also explain the strategies that I use to support myself and others and explore ways to support neurodivergent people within your setting.<br/>
    <br/>
    The best people to learn about neurodiversity from are neurodivergent people themselves.<br/> 
    </br>
    </br>
    Upcoming sessions can be found - <a href="/courses">click here</a><br/>
    To explore training opportunities in your setting - <a href="/trainings">click here</a> - or get in touch to discuss your requirements.
  `;

  // Animation info
  const description3 = `
  I create animations and infographic that can be used for a range of purposes, including training. These short 2-4 minute animations can be commissioned to cover any topic surrounding neurodiversity. Commissions have included:<br/>
  <br/>
  - Listening and attention in schools<br/>
  - Monotropic focus<br/>
  - Shared responsibility for communication<br/>
  - Autism and ADHD co-occurrence<br/>
  - Animations and Infographics to support the presentation of university research projects<br/>
  `;

  // Resources info
  const description4 = `
    I produce artwork and information packs that can be used to support people to understand different aspects of neurodiversity. These include information packs, resources for use in schools, and visual resources. These can be commissioned for any training purposes or are available to purchase online.
  `;


  return (
    <div style={{fontSize}}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>AJ's Brain</title>
        <meta
          name="description"
          content="Autistic and ADHD 🧠 BA Psychology with Child Development 🎓 Creating neurodiversity training, training materials, and animations"
        />
      </Helmet>

      {!isMobile && (
        <div className="row">
          <div className="column">
            <img src={trainingBedlington} alt="Training at Bedlington Station" className="home-image" />
          </div>
          <div className="column">
            <img src={ajScreen} alt="Presenting on Zoom" className="home-image" />
          </div>
          <div className="column">
            <img src={trainingHall} alt="Parenting Training Session" className="home-image" />
          </div>
        </div>
      )}

      {/* Grid 1 */}
      <GridSection
        title="Who is AJ?"
        description={<FormattedText text={description1} />}
        imageUrl={ajImage}
        imageAlt="Animated image of AJ"
        linkTo="/about"
        buttonText='About Me'
      />

      {/* Grid 2 */}
      <GridSection
        title="Training Info"
        description={<FormattedText text={description2} />}
        imageUrl={trainingImage}
        imageAlt="Image of training session in a school"
        linkTo="/trainings"
        reverseSection={true}
      />

      {/* Grid 3 */}
      <GridSection
        title="Animation Info"
        description={<FormattedText text={description3} />}
        vidUrl={animationsVid}
        vidAlt="Title Screen from an Attention and Listening animation"
        linkTo="/animations"
        vidSection={true}
      />

      {/* Grid 4 */}
      <GridSection
        title="Resources"
        description={<FormattedText text={description4} />}
        imageUrl={resourcesImage}
        imageAlt="Image of resources example"
        linkTo="/resources"
        reverseSection={true}
      />

      <Testimonials />
    </div>
  );
};

export default Home;
