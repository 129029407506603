import React, {useState, useRef} from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import './Navbar.css';

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const resourcesButtonRef = useRef(null);

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <Box className={`NavBox ${isMenuOpen ? 'expanded' : 'collapsed'}`} data-testid="navBar">
      <div className="hamburger" onClick={toggleMenu}>
        &#9776; {/* Unicode character for hamburger menu */}
      </div>

      <MenuItem color="inherit" className='NavButton' onClick={closeMenu} component={Link} to="/"><span className='navText'>Home</span></MenuItem>
      <MenuItem color="inherit" className='NavButton' onClick={closeMenu}component={Link} to="/about"><span className='navText'>About</span></MenuItem>
      <MenuItem color="inherit" className='NavButton' onClick={closeMenu}component={Link} to="/trainings"><span className='navText'>Training</span></MenuItem>

      {!isMenuOpen && (
        <><MenuItem
          color="inherit"
          ref={resourcesButtonRef}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className='NavButton'
          component={Link} to="/resources"
          onClick={closeMenu}
        >
          <span className='navText'>Resources</span>
        </MenuItem><Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={resourcesButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Box sx={{pointerEvents: 'auto'}}>
            <MenuItem onClick={handlePopoverClose} component={Link} to="/animations">Animations</MenuItem>
          </Box>
        </Popover></>
      )}
      {isMenuOpen && (
        <><MenuItem color="inherit" className='NavButton' onClick={closeMenu} component={Link} to="/resources"><span className='navText'>Resources</span></MenuItem>
          <MenuItem color="inherit" className='NavButton' onClick={closeMenu} component={Link} to="/animations"><span className='navText'>Animations</span></MenuItem></>
      )}


      <MenuItem color="inherit" className='NavButton' onClick={closeMenu} component={Link} to="/contact"><span className='navText'>Contact</span></MenuItem>

      <div className="up-arrow" onClick={toggleMenu}>
        &#94; {/* Unicode character for up arrow */}
      </div>
    </Box>
  );
};

export default NavBar;
