import React from 'react';
import {
  Typography,
  Divider,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Helmet} from 'react-helmet-async';
import GridSection from '../sections/gridSection/GridSection';
import vid1 from './videos/vid1.mp4';
import vid2 from './videos/vid2.mp4';
import vid3 from './videos/vid3.mp4';
import PropTypes from 'prop-types';
import './Animation.css';

function FormattedText({text}) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const AnimationPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  const text1 = `
    Here is a sample of publicly available animations. For commissions please get in touch at ajsbrain@gmail.com or via the contact page and for trainings please visit the training page <a href="/trainings">here</a>.</br>
    `;

  const text2 = `
    <a href="/contact">Get in touch</a></br>
    `;

  const description1 = `
    Monotropism challenges the theories describing autistic thinking as rigid, restrictive or ‘being on their own agenda’.</br>
    For autistic and ADHD people, our attention is actually driven by our interests.</br>
    We can give much more attention to the topics that interest us, and find it more difficult to switch attention if a topic is not as interesting.</br> 
    In short… be more interesting and you’ll have all of our attention 😄 </br>
    </br>
    Thanks to Jade this time for the voiceover 😊</br>
    </br>
    Full animation available on request. </br>
    Can be used for training purposes. Title - Monotropism in Schools</br>
    <a href="/contact">Get in touch</a> if you’re interested 😄
  `;

  const description2 = `
    Message to all teachers…</br> 
    </br> 
    Taken from my attention and listening/ADHD in school training animation.</br>  
    It’s also interesting to see how my animation skills have developed since I created this 😄</br> 
    </br>
    Thanks to Poppy for the voiceover.</br> 
    </br> 
    Full animation available on request. </br>
    Can be used for training purposes. Title - Attention and listerning/ADHD in School</br>
    <a href="/contact">Get in touch</a> if you’re interested 😄
  `;

  const description3 = `
    Another animation I’ve created for the same specialist teaching team about some of the complexities of being autistic and having ADHD. </br> 
    </br>
    Thanks to Chloe for the voiceover 😄</br> 
    </br> 
    Full animation available on request. </br>
    Can be used for training purposes. Title - Autism and ADHD</br>
    <a href="/contact">Get in touch</a> if you’re interested 😄
  `;

  return (
    <div style={{fontSize}}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>AJ's Brain | Animation</title>
        <meta
          name="description"
          content="A collection of educational animations"
        />
      </Helmet>
      <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
          Animation
      </Typography>
      <Divider />
      <div style={{maxWidth: '2000px', margin: '100px 20px'}}>
        <div style={!isMobile ? {maxWidth: '1000px', margin: '0 auto', textAlign: 'center'} : {textAlign: 'center'}}>
          <Typography variant="body" gutterBottom>{<FormattedText text={text1} />}</Typography>
        </div>
      </div>

      {/* Grid 1 */}
      <GridSection
        title="What is Monotropism?"
        description={<FormattedText text={description1} />}
        vidUrl={vid1}
        vidAlt="Title Screen from an Attention and Listening animation"
        linkTo="https://fb.watch/oh8aUQsFOy/"
        vidSection={true}
        control={true}
        buttons={false}
      />

      {/* Grid 2 */}
      <GridSection
        title="Attention and Listerning"
        description={<FormattedText text={description2} />}
        vidUrl={vid2}
        vidAlt="Title Screen from an Attention and Listening animation"
        linkTo="/animations"
        reverseSection={true}
        vidSection={true}
        control={true}
        buttons={false}
      />

      {/* Grid 3 */}
      <GridSection
        title="Autism and ADHD"
        description={<FormattedText text={description3} />}
        vidUrl={vid3}
        vidAlt="Title Screen from an Attention and Listening animation"
        linkTo="/animations"
        vidSection={true}
        control={true}
        buttons={false}
      />

      <div style={{maxWidth: '2000px', margin: '50px 20px'}}>
        <div style={!isMobile ? {maxWidth: '1000px', margin: '0 auto', textAlign: 'center'} : {textAlign: 'center'}}>
          <Typography variant="body" gutterBottom>{<FormattedText text={text2} />}</Typography>
        </div>
      </div>
    </div>
  );
};

export default AnimationPage;
