import React from 'react';
// import {Typography, Divider} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import GridSection from '../sections/gridSection/GridSection';
import coarseImage1 from './images/Understanding Autism.jpg';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';

function FormattedText({text}) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const CoursesPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  // coarses info
  const text2 = `
  I’m excited to bring you a new course for parents and families of young autistic people (no formal diagnosis needed)<br/><br/>
  This is a 4 week course where we will explore autism from an autistic perspective. I will use my lived experience to share some of the joys and challenges of being autistic, discuss some of the things that work for me, and help you to understand your young people.<br/>
  <br/>
  Venue: Holystone Primary School, Whitley Road, Holystone, Newcastle, NE27 ODA<br/>
  Time: 6:00pm - 8:00pm<br/>
  Price: £65<br/>
  <br/>
  Dates:<br/>
  <br/>
  Primary Aged - <a href="mailto:ajsbrain@gmail.com?subject=AJ's Brain - Understanding Autism - Primary Aged">Enquire via Email</a><br/>
  Monday 23rd September 2024<br/>
  Monday 30th September 2024<br/>
  Monday 7th October 2024<br/>
  Monday 14th October 2024<br/>
  </br>
  Secondary Aged - <a href="mailto:ajsbrain@gmail.com?subject=AJ's Brain - Understanding Autism - Secondary Aged">Enquire via Email</a><br/>
  Monday 11th November 2024<br/>
  Monday 18th November 2024<br/>
  Monday 25th November 2024<br/>
  Monday 2nd December 2024<br/></br>

  There is limited availability on each course, and I’m expecting them to fill up quickly, so please get it touch and book your place. This will be done on a first come, first served basis. <br/>



`;

  return (
    <div style={{fontSize}}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>AJ's Brain | Courses</title>
        <meta
          name="description"
          content="Courses offered by AJ"
        />
      </Helmet>
      <div style={{marginBottom: '50px'}}>
        {/* <div style={{marginBottom: '50px'}}>
          <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
            Courses
          </Typography>
          <Divider />
        </div> */}
        {/* Grid 1 */}
        <GridSection
          title="Understanding Autism"
          description={<FormattedText text={text2} />}
          imageUrl={coarseImage1}
          imageAlt="Course Description: Same as above "
          buttons={false}
        />
      </div>
    </div>
  );
};

export default CoursesPage;
