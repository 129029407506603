import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <h2>Introduction</h2>
      <p>
            Welcome to ajsbrain.co.uk (&quot;we&quot;, &quot;our&quot;, &quot;us&quot;). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we handle any personal information we collect from you or that you provide to us. By using our website, you agree to the terms of this policy.
      </p>

      <h2>Information We Collect</h2>
      <p>
            While we do not collect personal data directly through our website, we do collect information via email or other sources to supply our services. This may include:
      </p>

      <h3>For Customers</h3>
      <ul>
        <li>Name</li>
        <li>Contact details such as address, telephone number, email address</li>
        <li>Invoice address</li>
        <li>Payment details</li>
      </ul>

      <h3>For people who supply us with goods and services</h3>
      <ul>
        <li>Name</li>
        <li>Contact details such as address, telephone number, email address</li>
        <li>Bank details or payment methods</li>
      </ul>

      <h2>How we get the personal information and why we have it</h2>
      <p>
            Most of the personal information we process is provided to us directly by you when you:
      </p>
      <ul>
        <li>Contact us to enquire about services</li>
        <li>Purchase one of our services</li>
        <li>Contact or interact through our social media platforms</li>
        <li>Give us feedback</li>
        <li>Payment details when paying by BACS or cheque</li>
      </ul>

      <h2>How We Use Collected Information</h2>
      <p>
            The information collected is used to:
      </p>
      <ul>
        <li>Register you for courses and training sessions.</li>
        <li>Communicate with you regarding the service provided.</li>
        <li>Provide customer support and respond to enquiries.</li>
      </ul>

      <h2>Third-Party Services</h2>
      <p>
            While we do not collect personal data directly through our website, we use third-party services that may collect data on our behalf:
      </p>
      <ul>
        <li>
          <strong>Cloudflare</strong>: We use Cloudflare for DNS services and to protect and speed up our website. Cloudflare may collect your IP address and use cookies to identify malicious visitors, reduce bot activity, and improve our site&apos;s performance, as well as collecting analytics data. For more details, please review <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">Cloudflare&apos;s Privacy Policy</a>.
        </li>
        <li>
          <strong>Amazon Web Services (AWS)</strong>: Our website is hosted on AWS, which may collect your IP address and other data necessary for hosting and security purposes. For more details, please review <a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">AWS&apos;s Privacy Policy</a>.
        </li>
        <li>
          <strong>Other Third-Party Services</strong>: We may use or link to other third-party services that could potentially collect data, such as analytics. These services may collect IP addresses, cookies, and other data necessary for their functioning. Please refer to the privacy policies of these services for more information.
        </li>
      </ul>

      <p>
            We do not share or disclose your personal data to any third parties, except as required by law or as necessary for the third-party services we utilize to function.
      </p>

      <h2>UK GDPR</h2>
      <p>
      Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are:
      </p>
      <ul>
        <li>We have a contractual obligation – for example, when you buy one of our services or where we buy services or supplies to enable us to run our business.</li>
        <li>We have a legal obligation – for example, where we retain data for HMRC reporting purposes.</li>
        <li>We have a legitimate interest – for example, we may need to contact you to ask for further information about the services you have purchased or enquired about, we may contact you about a review that you have left (if you have consented to being contacted).</li>
      </ul>

      <h2>Your data protection rights</h2>
      <p>
      Under data protection law, you have rights including:
      </p>
      <ul>
        <li>Your right of access - You have the right to ask us for copies of your personal information.</li>
        <li>Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
        <li>Your right to erasure - You have the right to ask us to erase yourpersonal information in certain circumstances.</li>
        <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
        <li>Your right to object to processing - You have the the right to object to the processing of your personal information in certain circumstances.</li>
        <li>Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
        <li>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</li>
      </ul>
      <p>
      Please contact us at <a href="mailto:ajsbrain@gmail.com">ajsbrain@gmail.com</a> if you wish to make a request.
      </p>

      <h2>How to complain</h2>
      <p>
      If you have any concerns about our use of your personal information, you can make a complaint to us at <a href="mailto:ajsbrain@gmail.com">ajsbrain@gmail.com</a>.
      </p>
      <p>
      You can also complain to the ICO if you are unhappy with how we have used your data.<br /><br />
      The ICO’s address:<br /><br />
      Information Commissioner’s Office<br />
      Wycliffe House<br />
      Water Lane<br />
      Wilmslow<br />
      Cheshire<br />
      SK9 5AF<br /><br />
      Helpline number: 0303 123 1113<br />
      ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a>.<br />
      </p>

      <h2>Data Retention</h2>
      <p>
            We retain personal data collected via email for as long as necessary to provide our services, comply with our legal obligations, resolve disputes, and enforce our agreements. Data retention policies of the third-party services we use will apply to data collected through those services. Please consult their privacy policies for more information on how long they retain your data.
      </p>

      <h2>Security</h2>
      <p>
            We take the security of your data seriously and implement appropriate technical and organizational measures to protect your personal data. We also work with reputable third-party services that comply with industry standards for data protection and security.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
            We may update this Privacy Policy from time to time. Any changes we make will be posted on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
            If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p><a href="mailto:ajsbrain@gmail.com">ajsbrain@gmail.com</a></p>

      <p>
            By using our website and communicating with us via email or social media, you acknowledge that you have read and understood this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
