// colors.js
export const COLORS = {
  // Background
  primaryBg: '#ffb0a9',
  secondaryBg: '#ffbeb9',
  headerBg: '#f67d7d',

  // Text
  primaryTitle: '#ff0000',
  primaryText: '#ff0000',

  // Buttons
  primaryButton: '#f67d7d',
  secondaryButton: '#ff0000',
  hoverBotton: '#ffb0a9',


  // Cards
  primaryCard: '#f67d7d',

  // Logo
  logoBg: '#ffb0a9',
  logoText: '#c40100',
  logoBrain: '#ff94da',
};

