/* eslint-disable react/no-unescaped-entities */
import React, {useRef, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid, Button, Divider} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {COLORS} from '../../../common/resources/colors';
import PropTypes from 'prop-types';

// Function to format text from string to typography for the descriptions
function formatText(text) {
  return (
    <React.Fragment>
      {text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

formatText.propTypes = {
  text: PropTypes.string.isRequired,
};

const TextArea = ({title, description, linkTo, isMobile, buttons, buttonText}) => {
  const fontSize = isMobile ? '1rem' : '1.75rem';

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', minHeight: '100%', fontSize}}>
      <div style={{flexGrow: 1}}>
        <div style={{marginLeft: '20px', marginRight: '20px'}}>
          <Typography variant="body" gutterBottom>
            {description}
          </Typography>
        </div>
      </div>
      {buttons && (
        <Link to={linkTo} style={{textDecoration: 'none', alignSelf: 'flex-end', marginTop: '30px'}}> {/* For right hand button */}
          {/* <Link to={linkTo} style={{textDecoration: 'none', alignSelf: 'center', marginTop: '30px', display: 'flex', justifyContent: 'center'}}> */} {/* For centered button */}
          <Button
            variant="contained"
            sx={{
              'backgroundColor': COLORS.primaryButton,
              'color': '#FFF',
              '&:hover': {
                backgroundColor: COLORS.hoverBotton,
              },
              'padding': '16px 24px', // Adjust the padding to make the button larger
              'fontSize': '1.25rem', // Keep the text size the same
              'borderRadius': '25px', // Set the border radius to make the corners smooth
            }}
          >
            {buttonText}
          </Button>
        </Link>
      )}
    </div>
  );
};

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  buttons: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const VideoComponent = ({vidUrl, control = false}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(!control); // Start paused if control is true
  const [userInteracted, setUserInteracted] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlayButton = () => {
    setUserInteracted(true);
    if (control) {
      // If controls are true, use the browser's default play method
      videoRef.current.play();
    } else {
      attemptPlay();
    }
  };

  const attemptPlay = () => {
    if (videoRef.current && !control) { // Don't auto-play if control is true
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(handlePlay).catch(handlePause);
      }
    }
  };

  useEffect(() => {
    if (!control && videoRef.current && !userInteracted) {
      // Don't auto-play if control is true
      attemptPlay();
    }
  }, [userInteracted, control]);

  return (
    <div style={{position: 'relative'}}>
      <video
        ref={videoRef}
        width="100%"
        height="auto"
        src={vidUrl}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        muted
        loop
        playsInline
        title="Video"
        onPlay={handlePlay}
        onPause={handlePause}
        controls={control} // Show controls if control is true
        data-testid="video"
      ></video>
      {!isPlaying && !control && ( // Don't show play button if controls are true
        <button
          onClick={handlePlayButton}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '1.5em',
            padding: '10px 20px',
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            border: 'none',
            borderRadius: '5px',
            display: userInteracted ? 'none' : 'block', // Hide button after user interaction
          }}
        >
          Play Video
        </button>
      )}
    </div>
  );
};

VideoComponent.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  vidUrl: PropTypes.string.isRequired,
  control: PropTypes.bool,
};

const GridSection = ({title, description, imageUrl, imageAlt, vidUrl, vidAlt, linkTo, reverseSection = false, vidSection = false, control = false, buttons = true, buttonText = 'Find Out More'}) => {
  const isMobile = useMediaQuery('(max-width: 800px)'); // Adjust the max-width value as needed

  return (
    <div style={{marginBottom: '50px'}}>
      <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
        {title}
      </Typography>
      <Divider />
      <Grid container spacing={5} sx={{mt: 3}}>
        {vidSection && (
          <>
            {(!reverseSection || isMobile) && (
              <Grid item xs={12} md={8} sx={{display: 'flex', alignItems: 'center', p: 2}}>
                <TextArea title={title} description={description} linkTo={linkTo} isMobile={isMobile} buttons={buttons} buttonText={buttonText}/>
              </Grid>
            )}
            <Grid item xs={12} md={4} sx={{p: 2}}>
              <VideoComponent vidUrl={vidUrl} isMobile={isMobile} control={control} />
            </Grid>
            {reverseSection && !isMobile && (
              <Grid item xs={12} md={8} sx={{display: 'flex', alignItems: 'center', p: 2}}>
                <TextArea title={title} description={description} linkTo={linkTo} isMobile={isMobile} buttons={buttons} buttonText={buttonText}/>
              </Grid>
            )}
          </>
        )}
        {!vidSection && (
          <>
            {(!reverseSection || isMobile) && (
              <Grid item xs={12} md={8} sx={{display: 'flex', alignItems: 'center', p: 2}}>
                <TextArea title={title} description={description} linkTo={linkTo} isMobile={isMobile} buttons={buttons} buttonText={buttonText}/>
              </Grid>
            )}
            <Grid item xs={12} md={4} sx={{p: 2}}>
              {imageUrl && <img src={imageUrl} alt={imageAlt} className="home-avatar" />}
            </Grid>
            {reverseSection && !isMobile && (
              <Grid item xs={12} md={8} sx={{display: 'flex', alignItems: 'center', p: 2}}>
                <TextArea title={title} description={description} linkTo={linkTo} isMobile={isMobile} buttons={buttons} buttonText={buttonText}/>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

GridSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  vidUrl: PropTypes.string,
  vidAlt: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  reverseSection: PropTypes.bool,
  vidSection: PropTypes.bool,
  control: PropTypes.bool,
  buttons: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default GridSection;
