import React from 'react';
import trainingOffer from './images/TrainingOffer.png';

const TrainingOfferPage = () => {
  return (
    <>
      <img
        src={trainingOffer}
        alt="Training Offer Image"
        style={{width: '80%', maxWidth: '1800px', margin: 'auto', display: 'block'}}
      />
    </>
  );
};

export default TrainingOfferPage;
