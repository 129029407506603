import React from 'react';
import {Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// import {COLORS} from '../../../common/resources/colors';
import './Testimonials.css';
import logoImage from './images/logo.png';

const reviews = [
  {
    review: '“Everything being explained from experience has helped me understand emotions, feelings, reasons why and how to approach things differently.”',
    name: 'Mark P',
    date: 'July 2024',
    image: 'review1.jpg',
  },
  {
    review: '"Gritty, thoughtful, honest account with intelligence and wit!"',
    name: 'Conference Feedback',
    date: 'April 2024',
    image: 'review2.jpg',
  },
  {
    review: '“As a mental health nurse, I feel like I have always taken a very clinical view. This course has helped me to not look at autism as ‘a problem’ or ‘difficulty’ to be managed, but more to be understood and embraced and I will certainly try to promote this.”',
    name: 'Amy C',
    date: 'September 2023',
    image: 'review2.jpg',
  },
  // Not Used
  {
    review: '“Really helpful hearing and seeing AJ’s vision and concept of life being neurodivergent.”',
    name: 'Anonymous',
    date: 'September 2023',
    image: 'review2.jpg',
  },
  {
    review: '“This training was amazing, I’ve told so many schools about it!”',
    name: 'Anonymous',
    date: 'October 2023',
    image: 'review2.jpg',
  },
];

const Testimonials = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  return (
    <div className="testimonialLayout" style={{fontSize}}>
      {!isMobile && (
        <div className="logoContainer">
          <img src={logoImage} alt="Website Logo" className="websiteLogo"/>
        </div>
      )}
      <div className="speechBubble">
        {reviews.slice(0, 3).map((review, index) => (
          <div key={index} className="reviewBox">
            <div className="reviewContent" style={{backgroundColor: 'transparent'}}>
              <Typography variant="body1" className="reviewText">{review.review}</Typography>
            </div>
            <div className="reviewFooter" style={{backgroundColor: 'transparent'}}>
              <Typography variant="caption" className="reviewName">{review.name},      </Typography>
              <Typography variant="caption" className="reviewDate">{review.date}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
