import React from 'react';
import {Typography, Card, CardContent, Link, Divider} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {COLORS} from '../../common/resources/colors';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import './Contact.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';

function FormattedText({text}) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const socialMediaData = [
  {
    icon: (
      <a href="https://www.facebook.com/profile.php?id=100094968372747" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} style={{fontSize: '48px', color: 'blue'}} />
      </a>
    ),
    title: 'Facebook',
    description: 'Join our community on Facebook.',
    link: 'https://www.facebook.com/profile.php?id=100094968372747',
  },
  {
    icon: (
      <a href="https://www.instagram.com/ajs.brain/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} style={{fontSize: '48px', color: 'purple'}} />
      </a>
    ),
    title: 'Instagram',
    description: 'Follow us on Instagram for visual content.',
    link: 'https://www.instagram.com/ajs.brain/',
  },
  {
    icon: (
      <a href="https://www.linkedin.com/company/aj-s-brain/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} style={{fontSize: '48px', color: 'blue'}} />
      </a>
    ),
    title: 'LinkedIn',
    description: 'Follow on Linkedin for updates and training.',
    link: 'https://www.linkedin.com/company/aj-s-brain/',
  },
];

const ContactPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  const text1 = `
  To commission work or enquire about training, please reach out via email to <a href="mailto:ajsbrain@gmail.com">ajsbrain@gmail.com</a><br/>
  <br/>
  To keep up to date with new content, take part in discussions, and engage in the neurodivergent community, follow us on social media:
`;

  return (
    <div style={{fontSize}}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>AJ's Brain | Contact</title>
        <meta
          name="description"
          content="Get in touch for commissions and training"
        />
      </Helmet>
      <div style={{textAlign: 'center', marginBottom: '50px'}}>
        <div style={{marginBottom: '50px'}}>
          <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
            Contact
          </Typography>
          <Divider />
        </div>
        <div style={{maxWidth: '1000px', marginLeft: '20px', marginRight: '20px'}}>
          <Typography variant="body" gutterBottom>{<FormattedText text={text1} />}</Typography>
        </div>
      </div>
      <div className="social-media-container">
        {socialMediaData.map((socialMedia, index) => (
          <Card key={index} className="social-media-card" style={{backgroundColor: COLORS.primaryCard}} data-testid="socialCard">
            <div className="icon-container">{socialMedia.icon}</div>
            <CardContent>
              <Typography variant="h6" component="div">
                {socialMedia.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {socialMedia.description}
              </Typography>
              <Link href={socialMedia.link} target="_blank" rel="noopener" variant="h5">
                Visit {socialMedia.title}
              </Link>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ContactPage;
