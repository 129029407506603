import React from 'react';
import PropTypes from 'prop-types';
import Footer from './footer/Footer';
import Header from './header/Header';
import {Alert, AlertTitle} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {COLORS} from './resources/colors';
import './AppShell.css';

function AppShell({children}) {
  // Change to true if testing or false for production
  const isInTestingMode = false;
  const isMobile = useMediaQuery('(max-width: 800px)');
  const margin = isMobile ? '0' : '0 auto';
  const padding = isMobile ? '10px' : '50px';

  return (
    <div className={`app-shell-container`} style={{backgroundColor: COLORS.primaryBg, minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      <Header />

      <div className="testing-alert">
        {isInTestingMode && (
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This site is in active development — <strong>May not work as intended!</strong>
          </Alert>
        )}
      </div>

      <div className="scrollable-container" style={{flex: '1', overflowY: 'auto', maxWidth: '2000px', margin, padding}}>
        {children}
      </div>

      <div className={`footer`}>
        <Footer />
      </div>
    </div>
  );
}

AppShell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppShell;
