import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Helmet} from 'react-helmet-async';
import GridSection from '../sections/gridSection/GridSection';
import packsImage from './images/packsImage.jpeg';
import activityImage from './images/activityImage.jpg';
import doodleImage from './images/doodleImage.png';
import PropTypes from 'prop-types';

function FormattedText({text}) {
  return (
    <div
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const ResourcesPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  const description1 = `
  I provide a wide range of information packs and drawings on neurodiversity, ADHD and autism. <br/>
  <br/>
  For free packs and resources that could be useful for training or for sharing with others, click the google drive link below. <br/>
  If you would like something specific creating please <a href="/contact">get in touch</a>. <br/>
`;

  const description2 = `
  Here is a range of free downloadable visuals which may be of help. More will be added as they are created.<br/>
  <br/>
  If you would like something specific creating please <a href="/contact">get in touch</a>. <br/>
`;

  const description3 = `
  I love to doodle and draw, these are also available to view or download via Google Drive. <br/>
  <br/>
  If you would like something specific creating please <a href="/contact">get in touch</a>. <br/>
`;

  return (
    <div style={{fontSize}}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>AJ's Brain | Resources</title>
        <meta
          name="description"
          content="Resources for training and education"
        />
      </Helmet>

      {/* Grid 1 */}
      <GridSection
        title="Packs"
        description={<FormattedText text={description1} />}
        imageUrl={packsImage}
        imageAlt="Example pack image"
        linkTo="https://drive.google.com/drive/folders/1bIochCZEJU-cuIusJ-PCleuziIIYZGrc?usp=sharing"
        buttonText='Google Drive'
      />

      {/* Grid 2 */}
      <GridSection
        title="Activities"
        description={<FormattedText text={description2} />}
        imageUrl={activityImage}
        imageAlt="Example activity image"
        linkTo="https://drive.google.com/drive/folders/1JFo7wTHl_-jirMl05oiQXVnUIuK1s5Zp?usp=sharing"
        reverseSection={true}
        buttonText='Google Drive'
      />

      {/* Grid 3 */}
      <GridSection
        title="Colouring and Doodle Sheets"
        description={<FormattedText text={description3} />}
        imageUrl={doodleImage}
        imageAlt="Example doodle image"
        linkTo="https://drive.google.com/drive/folders/1w-omKtqM71OCyD6uKDGpFxMddieG5AYz?usp=sharing"
        buttonText='Google Drive'
      />
    </div>
  );
};

export default ResourcesPage;
