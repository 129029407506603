// index.js
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import './index.css';
import AppShell from './common/AppShell';
import HomePage from './pages/home/Home';
import AboutPage from './pages/about/About';
import ContactPage from './pages/contact/Contact';
import UnderstandingAutismPage from './pages/courses/UnderstandingAutism';
import TrainingPage from './pages/training/Training';
import TrainingOfferPage from './pages/training/TrainingOffer';
import AnimationPage from './pages/animation/Animation';
import ResourcesPage from './pages/resources/Resources';
import PrivacyPage from './pages/privacy/Privacy';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './scrollToTop';
import '@fortawesome/fontawesome-free/css/all.min.css';


// Import createRoot from "react-dom/client" instead of "react-dom"
import {createRoot} from 'react-dom/client';

createRoot(document.getElementById('root')).render(
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <AppShell>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/understandingAutism" element={<UnderstandingAutismPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/trainings" element={<TrainingPage />} />
            <Route path="/trainingOffer" element={<TrainingOfferPage />} />
            <Route path="/animations" element={<AnimationPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Routes>
        </AppShell>
      </BrowserRouter>
    </HelmetProvider>,
);

reportWebVitals();
