/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import GridSection from '../sections/gridSection/GridSection';
import ajImage from './images/Training_1.jpg';
import schoolTraining from './images/Training_Bedlington.jpeg';
import businessTraining from './images/Training_Hall.jpeg';
import familiesTraining from './images/AJ_Screen.jpg';

function FormattedText({text}) {
  return (
    <div dangerouslySetInnerHTML={{__html: text}} />
  );
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const TrainingPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const fontSize = isMobile ? '1rem' : '1.75rem';

  // School info
  // TODO: Add link to the training offer
  const description1 = `
    I have worked in a number of schools across North Tyneside and Northumberland, delivering training for staff and parents, and have also worked alongside groups of children to discuss autism, ADHD, and what it’s like to have a different brain.<br/>
    <br/>
    "I will be much more aware of how I react to all children. With the knowledge gained today, I now understand how it feels to have ADHD. Brilliant!"<br/>
    <br/>
    "So helpful and informative! Excellent delivery! Much better to learn about it from a personal viewpoint."<br/>
    <br/>
    "Incredible training Amy - Informative, beautifully delivered and hugely appreciated!"<br/>
  `;

  // Business info
  const description2 = `
    I have worked with a number of businesses locally and nationally and can deliver training both in person (in the North East) or online. I also provide consultancy.<br/>
    <br/>
    Areas for exploration include:<br/>
    <br/>
    - Understanding neurodiversity, specifically autism and ADHD.<br/>
    - The impact of autism and/or ADHD in the workplace.<br/>
    - How to support the wellbeing and productivity of a neurodiverse workforce.<br/>
    - How to meet the needs of your autistic and/or ADHD clients.<br/> 
    <br/>
    "Really engaging and enjoyable session. Brilliantly delivered and the perfect level for our role and current level of understanding - I’m looking forward to implementing some of the points discussed. Thank you!"<br/>
    <br/>
    "Absolutely insightful session will be so helpful in life and with our client group."<br/>
    <br/>
    "Full of admiration for AJ for her honesty in her presentation."<br/>
  `;

  // Families info
  const description3 = `
    I currently run 4 week parent/carer courses (1 session per week) for families in North Tyneside. I also provide training for family support groups and organisations.<br/>
    <br/>
    I will be delivering online parent/carer sessions in the near future - watch this space.<br/>
    <br/>
    There are a number of packs and resources to support families available on my Facebook/Instagram page or <a href="/resources">here</a><br/>
    <br/>
    "Having a personal perspective has been invaluable. Having an insight rather than someone just reading a textbook provides so much more."<br/>
    <br/>
    "I feel there has been a lot of lightbulb moments as to why things happen and I have a deeper knowledge now."<br/>
    <br/>
    "Really informative and I have a much greater insight to be able to support our son. We have already seen a difference."<br/>
    <br/>
    "[We] could see the change of approach working from the first week."<br/>
  `;

  // Health info
  const description4 = `
    I have worked with a number of health and mental health professionals locally and nationally. I can deliver training both in person (in the North East) or online. I also provide consultancy.<br/>
    <br/>
    This training makes the perfect follow up to the Oliver McGowan Mandatory Training in Learning Disability and Autism.<br/>
    <br/>
    Trainings can focus on:<br/>
    <br/>
    - Understanding neurodiversity, specifically autism and ADHD.<br/>
    - The impact of sensory differences on understanding and communicating pain, illness, anxiety and the signals our bodies send us (interoception).<br/>
    - Communicating effectively with neurodivergent patients/clients.<br/>
    - How to support the wellbeing and productivity of a neurodiverse workforce.<br/>
    <br/>
    "As a mental health nurse, I feel like I have always taken a very clinical view. This course has helped me to not look at autism as ‘a problem’ or ‘difficulty’ to be managed, but more to be understood and embraced and I will certainly try to promote this."<br/>
    <br/>
    "The whole session was so interesting - it will be so beneficial to me in my practice."<br/>
    <br/>
    "Thanks for a great session. I think the ‘lived experience’ way of sharing this information makes it really powerful and easier to understand."<br/>
  `;

  const text1 = `
      <br/>
      Training can be bespoke and discussed in advance. </br>
      <a href="/contact">Get in touch</a>

  `;

  return (
    <div style={{fontSize}}>
      <Helmet>
        <title>AJ's Brain | Training</title>
        <meta
          name="description"
          content="Information about the trainings offered by AJ's Brain"
        />
      </Helmet>
      {/* <Typography variant="h6" gutterBottom sx={{textAlign: 'center', fontSize: '2rem'}}>
        Training
      </Typography>
      <Divider /> */}

      {/* Grid 1 */}
      <GridSection
        title="Schools"
        description={<FormattedText text={description1} />}
        imageUrl={schoolTraining}
        imageAlt="Animated image of AJ"
        linkTo="/trainingOffer"
        buttonText='Training Offer'
      />

      {/* Grid 2 */}
      <GridSection
        title="Business"
        description={<FormattedText text={description2} />}
        imageUrl={businessTraining}
        imageAlt="Animated image of AJ"
        linkTo="/about"
        reverseSection={true}
        buttons={false}
      />

      {/* Grid 3 */}
      <GridSection
        title="Families"
        description={<FormattedText text={description3} />}
        imageUrl={familiesTraining}
        imageAlt="Animated image of AJ"
        buttonText='Understanding Autism Course'
        linkTo="/understandingAutism"
      />

      {/* Grid 4 */}
      <GridSection
        title="Health"
        description={<FormattedText text={description4} />}
        imageUrl={ajImage}
        imageAlt="Animated image of AJ"
        linkTo="/about"
        reverseSection={true}
        buttons={false}
      />
      <Divider />
      <div style={{fontSize, textAlign: 'center'}}>
        <FormattedText text={text1} />
      </div>

    </div>
  );
};

export default TrainingPage;
