import React from 'react';
import {Box, Typography} from '@mui/material';
import {COLORS} from '../resources/colors';
import './Footer.css';

const Footer = () => {
  return (
    <Box sx={{py: 3, textAlign: 'center'}} bgcolor={COLORS.headerBg} data-testid="footer">
      {/* Social media links */}
      <div className="social-media">
        <a href="https://www.facebook.com/profile.php?id=100094968372747" target="_blank" rel="noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        {/* <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
          <i className="fab fa-twitter"></i>
        </a> */}
        <a href="https://www.instagram.com/ajs.brain/" target="_blank" rel="noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        {/* <a href="https://www.pinterest.com" target="_blank" rel="noreferrer">
          <i className="fab fa-pinterest"></i>
        </a> */}
        {/* <a href="https://youtube.com/@AJsBrain" target="_blank" rel="noreferrer">
          <i className="fab fa-youtube"></i>
        </a> */}
        {/* <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
          <i className="fab fa-tiktok"></i>
        </a> */}
        {/* <a href="https://www.etsy.com" target="_blank" rel="noreferrer">>
          <i className="fab fa-etsy"></i>
        </a> */}
        <a href="https://www.linkedin.com/company/aj-s-brain/" target="_blank" rel="noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>

      {/* Footer items */}
      <div className="footer-items">
        {/* Footer item 1 */}
        <div className="footer-item">
          <Typography variant="body2" color="textSecondary">
            <a href="mailto:ajsbrain@gmail.com" style={{color: 'inherit', textDecoration: 'none'}}>ajsbrain@gmail.com</a>
          </Typography>
        </div>

        {/* Footer item 2 */}
        <div className="footer-item">
          <Typography variant="body2" color="textSecondary">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <a href="/privacy" style={{color: 'inherit', textDecoration: 'none'}}>Privacy Policy</a>
          </Typography>
        </div>

        {/* Footer item 3 */}
        <div className="footer-item">
          <Typography variant="body2" color="textSecondary">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            &copy; 2024 AJ's Brain - All rights reserved.
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
